export const XHR_TIMEOUT = 20000;
export const LOCAL_HEADERS = {
  'X-Requested-With': 'XMLHttpRequest'
};
export const POST_HEADERS = {
  'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
};
export const COMMON_HEADERS = {
  Accept: 'application/json'
};
