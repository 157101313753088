import {INFINITE_LIST_API_URL} from '../constants/infinite-list.js';
import enableInfiniteLoader from '../modules/infinite.js';

import {localGet} from './ajax/promise.js';

const tag18PlusPathname = '/tags/18/';

function getPageNum(page, pathname) {
  if (pathname === tag18PlusPathname) {
    return page;
  }

  const pageIndexMatches = pathname.match(/\d{1,}\//g);

  if (pageIndexMatches?.length) {
    return page - 1 + (+pageIndexMatches.pop().replace(/\//, ''));
  }

  return page;
}

export const initInfiniteList = entity => {
  const $infiniteContainer = document.querySelector('.js-infinite');

  if ($infiniteContainer) {
    enableInfiniteLoader({
      $container: $infiniteContainer,
      loadNext(page) {
        const {pathname, origin} = window.location;
        const {entityId} = window.Site;
        const url = entityId
          ? `${entity}/${entityId}/${getPageNum(page, pathname)}/`
          : `${entity}/${getPageNum(page, pathname)}/`;

        return localGet(`${origin}/${INFINITE_LIST_API_URL}${url}`);
      }
    });
  }
};
