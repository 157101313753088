import {getViewportHeight} from '../services/viewport.js';

export default function enableInfinite({
  $container,
  loadNext,
  beforeOnLoaded = () => {},
  onLoaded = () => {},
  margins = {top: 1.5, bottom: 1.5},
  htmlKey = '',
  defaultPage = 2,
  loaderVisibilityClass = 'loader--visible'
}) {
  const rootMargin = Math.min(getViewportHeight(), $container.getBoundingClientRect().height);
  const $loader = document.getElementById('js-infinite-loader');

  let enabled = true;

  let page;

  try {
    page = parseInt(new URL(window.location.href).searchParams.get('page'), 10) + 1 || defaultPage;
  } catch (e) {
    page = defaultPage;
  }

  const observer = new IntersectionObserver(changes => {
    if (!changes[0].isIntersecting && enabled) {
      enabled = false;
      $loader.classList.add(loaderVisibilityClass);

      loadNext(page)
        .then(data => {
          const html = htmlKey ? data?.[htmlKey] : data;

          enabled = !!html;
          $loader.classList.remove(loaderVisibilityClass);
          beforeOnLoaded(data, page, enabled);

          if (enabled) {
            $container.insertAdjacentHTML('beforeend', html);
            onLoaded(page, data);
            page = page + 1;
          } else {
            observer.unobserve($container);
          }
        })
        .catch(error => {
          enabled = true;
          $loader.classList.remove(loaderVisibilityClass);
          console.error(error);
        });
    }
  }, {rootMargin: `-${margins.top * rootMargin}px 0px ${margins.bottom * rootMargin}px 0px`});

  observer.observe($container);
}
