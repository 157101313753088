const isArray = arr => arr instanceof Array;

export const inNormalRange = status => status >= 200 && status < 400;

function getFormDataArray(key, array) {
  return array.map(item => `${key}[]=${item}`).join('&');
}

export function getFormData(object) {
  return Object.keys(object).map(key => {
    const value = object[key];

    return isArray(value) ? getFormDataArray(key, value) : `${key}=${value}`;
  }).join('&');
}
