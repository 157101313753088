const DESKTOP_WIDTH_EDGE = 1024;

export const getViewportWidth = () => window.innerWidth || document.documentElement.clientWidth;
export const getViewportHeight = () => window.innerHeight || document.documentElement.clientHeight;

export function elementInViewport(element, topModifier = 0) {
  const bottom = element.getBoundingClientRect().bottom;

  return (bottom <= (getViewportHeight() + topModifier)) && (bottom > 0);
}

export function isVisible(element) {
  return elementInViewport(element, element.clientHeight);
}

export const isInDesktopRange = () => getViewportWidth() > DESKTOP_WIDTH_EDGE;
