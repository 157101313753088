import {COMMON_HEADERS, LOCAL_HEADERS, POST_HEADERS, XHR_TIMEOUT} from './constants.js';
import {getFormData, inNormalRange} from './services.js';

const ajax = (
  url,
  data,
  headers,
  method
) => new Promise((resolve, reject) => {
  const XHR = new XMLHttpRequest();

  XHR.open(method, url, true);

  Object.keys(headers).forEach(
    key => XHR.setRequestHeader(key, headers[key])
  );

  XHR.onreadystatechange = () => {
    if (XHR.readyState === XHR.DONE) {
      if (inNormalRange(XHR.status)) {
        resolve(XHR.responseText);
      } else {
        reject(XHR);
      }
    }
  };

  XHR.onerror = reject;

  XHR.ontimeout = reject;

  XHR.timeout = XHR_TIMEOUT;
  XHR.send(data);
});

export const post = (
  url,
  data = {},
  headers = {}
) =>
  ajax(
    url,
    getFormData(data),
    {
      ...headers,
      ...COMMON_HEADERS,
      ...POST_HEADERS
    },
    'POST'
  );

export const get = (
  url,
  headers = {}
) =>
  ajax(
    url,
    '',
    {
      ...headers,
      ...COMMON_HEADERS
    },
    'GET'
  );

export const localGet = (
  url,
  headers = {}
) =>
  get(
    url,
    {
      ...headers,
      ...LOCAL_HEADERS
    },
  );
